import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Observable, forkJoin, switchMap, timer } from 'rxjs';
import { PageLayoutComponent } from '@shared/layouts/page-layout/page-layout.component';
import { Badge } from '@shared/layouts/sidenav/sidenav.interface';
import { PAGES_ROUTES } from './pages.routes';
import { PagesService } from './pages.service';
import { NavigationScrollService } from '@shared/service/navigation-scroll/navigation-scroll.service';
import { DIALOGS_ROUTES } from './dialogs/dialogs.routes';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    standalone: true,
    imports: [PageLayoutComponent],
    providers: [PagesService],
})
export class PagesComponent implements OnInit, OnDestroy {
    private _service = inject(PagesService);
    private _navigationScrollService = inject(NavigationScrollService);

    public routes = PAGES_ROUTES;
    public badges$!: Observable<Badge>;
    public subMenus = [
        {
            path: 'dialogs',
            routes: DIALOGS_ROUTES,
        },
    ];

    ngOnInit(): void {
        this.initSubs();
        this._navigationScrollService.ngOnInit();
    }

    ngOnDestroy(): void {
        this._navigationScrollService.ngOnDestroy();
    }

    private initSubs(): void {
        this.badges$ = timer(0, 15_000).pipe(
            switchMap(() =>
                forkJoin({
                    dialogBadge: this._service.getBadge(),
                })
            )
        );
    }
}
