import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DialogsBadge } from './interface';
import { ApiService } from '@core/service/api';

@Injectable({
    providedIn: 'any',
})
export class ApiDialogsBadgeService {
    private _apiService = inject(ApiService);

    get(): Observable<DialogsBadge> {
        return this._apiService
            .get('brand-lk/v1/dialogs/badge')
            .pipe(map((res) => res.json));
    }
}
