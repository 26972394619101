import { Injectable, inject } from '@angular/core';
import { ApiDialogsBadgeService } from '@brandShared/service/methods/dialogs/badge';
import { Observable, map } from 'rxjs';

@Injectable()
export class PagesService {
    private _apiDialogsBadgeService = inject(ApiDialogsBadgeService);

    getBadge(): Observable<number> {
        return this._apiDialogsBadgeService.get().pipe(map((res) => res.badge));
    }
}
