import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '@core/service/api';
import { AuthCheck } from '@shared/service/methods/auth/check';

@Injectable({
    providedIn: 'any',
})
export class ApiAuthCheckService {
    private _apiService = inject(ApiService);

    checkauth(): Observable<AuthCheck> {
        return this._apiService
            .get('brand-lk/v1/auth/check')
            .pipe(map((res) => res.json));
    }
}
