import {
    enableProdMode,
    LOCALE_ID,
    importProvidersFrom,
    isDevMode,
} from '@angular/core';
import { environment } from '@core/environments/environment';
import { AppComponent } from './app/app.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouterModule, TitleStrategy } from '@angular/router';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { APP_ROUTES } from './app/app.routes';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TemplatePageTitleStrategy } from '@core/strategy/template-page-title/template-page-title.service';
import { LoginServiceInjection } from '@shared/service/methods/auth/login';
import { LogoutServiceInjection } from '@shared/service/methods/auth/logout';
import { CheckServiceInjection } from '@shared/service/methods/auth/check';
import { TelemetryServiceInjection } from '@shared/service/methods/auth/telemetry';
import { ApiAuthCheckService } from '@brandShared/service/methods/auth/check/check.service';
import { ApiAuthLoginService } from '@brandShared/service/methods/auth/login/login.service';
import { ApiAuthLogoutService } from '@brandShared/service/methods/auth/logout/logout.service';
import { ApiAuthTelemetryService } from '@brandShared/service/methods/auth/telemetry/telemetry.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RegionsServiceInjection } from '@shared/service/methods/regions';
import { ApiRegionsService } from '@brandShared/service/methods/regions/regions.service';
import { DrugsServiceInjection } from '@shared/service/methods/drugs';
import { ApiDrugsService } from '@brandShared/service/methods/drugs/drugs.service';
import { OrdersMonthsServiceInjection } from '@shared/service/methods/orders';
import { ApiAnalyticsOrdersService } from '@brandShared/service/methods/analytics/orders';
import { SuperadminServiceInjection } from '@shared/service/methods/superadmin';
import { ApiSuperadminService } from '@brandShared/service/methods/superadmin/superadmin.service';

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule,
            RouterModule.forRoot(APP_ROUTES, {
                paramsInheritanceStrategy: 'always',
                scrollPositionRestoration: 'disabled',
            }),
            HttpClientModule,
            NgSelectModule,
            ToastrModule.forRoot(),
            AngularSvgIconModule.forRoot(),
            ServiceWorkerModule.register('/ngsw-worker.js', {
                enabled: !isDevMode(),
            }),
        ),
        { provide: LOCALE_ID, useValue: 'ru-RU' },
        { provide: TitleStrategy, useClass: TemplatePageTitleStrategy },
        provideAnimations(),
        {
            provide: LoginServiceInjection,
            useClass: ApiAuthLoginService,
        },
        {
            provide: LogoutServiceInjection,
            useClass: ApiAuthLogoutService,
        },
        {
            provide: CheckServiceInjection,
            useClass: ApiAuthCheckService,
        },
        {
            provide: TelemetryServiceInjection,
            useClass: ApiAuthTelemetryService,
        },
        {
            provide: RegionsServiceInjection,
            useClass: ApiRegionsService,
        },
        {
            provide: DrugsServiceInjection,
            useClass: ApiDrugsService,
        },
        {
            provide: OrdersMonthsServiceInjection,
            useClass: ApiAnalyticsOrdersService,
        },
        {
            provide: SuperadminServiceInjection,
            useClass: ApiSuperadminService,
        },
    ],
}).catch((err) => console.error(err));
