import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '@core/service/api';
import { ParamsAuthTelemetry } from '@shared/service/methods/auth/telemetry';

@Injectable({
    providedIn: 'any',
})
export class ApiAuthTelemetryService {
    private _apiService = inject(ApiService);

    sendTelemetry(obj: ParamsAuthTelemetry): Observable<void> {
        return this._apiService
            .post('brand-lk/v1/auth/telemetry', obj)
            .pipe(map((res) => res.json));
    }
}
