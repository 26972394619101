import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '@core/service/api';
import {
    ParamsRegions,
    Regions,
    RegionsService,
} from '@shared/service/methods/regions';

@Injectable({
    providedIn: 'any',
})
export class ApiRegionsService implements RegionsService {
    private _apiService = inject(ApiService);

    list(obj?: ParamsRegions): Observable<Regions> {
        return this._apiService
            .get('brand-lk/v1/regions', obj)
            .pipe(map((res) => res.json));
    }
}
