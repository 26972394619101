import { Injectable, inject } from '@angular/core';
import { ApiService } from '@core/service/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ParamsSuperadminList,
    ParamsSuperadminSet,
    SuperadminList,
    SuperadminService,
    SuperadminSet,
} from '@shared/service/methods/superadmin';

@Injectable({
    providedIn: 'any',
})
export class ApiSuperadminService implements SuperadminService {
    private _apiService = inject(ApiService);

    list(obj?: ParamsSuperadminList): Observable<SuperadminList> {
        return this._apiService
            .get('brand-lk/v1/superadmin/brands', obj)
            .pipe(map((res) => res.json));
    }

    set(obj: ParamsSuperadminSet): Observable<SuperadminSet> {
        return this._apiService
            .post('brand-lk/v1/superadmin/brands/set', obj)
            .pipe(map((res) => res.json));
    }
}
