import { Injectable, inject } from '@angular/core';
import { ApiService } from '@core/service/api';
import { LogoutService } from '@shared/service/methods/auth/logout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'any',
})
export class ApiAuthLogoutService implements LogoutService {
    private _apiService = inject(ApiService);

    logout(): Observable<void> {
        return this._apiService
            .post('brand-lk/v1/auth/logout')
            .pipe(map((res) => res.json));
    }
}
